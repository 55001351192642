import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { turmaStore, mainStore } from '@/utils/store-accessor';
import AulaLocalShowButton from '@/components/AulaLocalShowButton.vue';
let TurmaAdicionarAlunoComponent = class TurmaAdicionarAlunoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.turmaId = null;
        this.loading = false;
        this.turmaCodigoReferencia = null;
        this.turmas = [];
        this.turmasItems = [];
    }
    get headers() {
        const h = [];
        h.push({ text: 'Turma', value: 'codigo_referencia' });
        h.push({ text: 'Livro', value: 'livro' });
        h.push({ text: 'Modalidade', value: 'modalidade_id' });
        h.push({ text: 'Sala', value: 'sala_nome' });
        h.push({ text: 'Professor', value: 'professor' });
        h.push({ text: 'Quantidade de alunos', value: 'numero_aluno' });
        h.push({ text: 'Vagas disponíveis', value: 'vagas_disponiveis' });
        h.push({ text: 'Dia/Hora', value: 'cronograma_horario' });
        h.push({ text: 'Último conteúdo', value: 'ultima_atividade' });
        if (this.userProfile.company.aula_online_allow) {
            h.push({ text: 'Local Aula', value: 'local_aula_id' });
        }
        h.push({ text: '', value: 'actions' });
        return h;
    }
    get turmaItemsFiltered() {
        return _.filter(this.turmasItems, (item) => item.status !== 'Cancelado');
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async escolherTurmaAluno(turma) {
        this.turmaId = turma.id;
        this.turmaCodigoReferencia = turma.codigo_referencia;
        // const ids = _.map(this.propTitulosItems, 'id');
        // await financeiroParcelaStore.cancelarFinanceiroParcelas({
        //   ids,
        //   observacao: this.motivoCancelamento,
        // });
        // this.onCancel();
    }
    async atualizaTurmaItens() {
        if (this.propTurmaAlunoId) {
            this.turmasItems = await turmaStore.getTurmasCompativelTransferencia(this.propTurmaAlunoId);
            this.$nextTick();
        }
        else {
            this.turmasItems = await turmaStore.getTurmas({
                livro_nome: this.propLivro,
            });
            this.$nextTick();
        }
    }
    async confirmar() {
        this.loading = true;
        await turmaStore.createTurmaAluno({
            turma_id: this.turmaId,
            contrato_id: this.propContratoId,
            aluno_id: this.propAlunoId,
            status_do_aluno_na_turma_id: 1,
            turma_aluno_id: this.propTurmaAlunoId,
            contrato_livro_id: this.propContratoLivroId,
        });
        this.loading = false;
        this.$emit('on-cancel');
    }
    onCancel() {
        this.turmaId = null;
        this.turmaCodigoReferencia = null;
        this.$emit('on-cancel');
    }
    async mounted() {
        this.loading = true;
        if (this.propTurmaAlunoId) {
            this.turmasItems = await turmaStore.getTurmasCompativelTransferencia(this.propTurmaAlunoId);
        }
        else {
            this.turmasItems = await turmaStore.getTurmas({
                livro_nome: this.propLivro,
                status_id: [1, 2],
            });
        }
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number })
], TurmaAdicionarAlunoComponent.prototype, "propLivroId", void 0);
__decorate([
    Prop({ type: String })
], TurmaAdicionarAlunoComponent.prototype, "propLivro", void 0);
__decorate([
    Prop({ type: Number })
], TurmaAdicionarAlunoComponent.prototype, "propContratoId", void 0);
__decorate([
    Prop({ type: Number })
], TurmaAdicionarAlunoComponent.prototype, "propAlunoId", void 0);
__decorate([
    Prop({ type: Number })
], TurmaAdicionarAlunoComponent.prototype, "propModalidadeId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], TurmaAdicionarAlunoComponent.prototype, "propTurmaAlunoId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], TurmaAdicionarAlunoComponent.prototype, "propContratoLivroId", void 0);
__decorate([
    Watch('propLivro')
], TurmaAdicionarAlunoComponent.prototype, "atualizaTurmaItens", null);
TurmaAdicionarAlunoComponent = __decorate([
    Component({
        components: {
            AulaLocalShowButton,
        },
    })
], TurmaAdicionarAlunoComponent);
export default TurmaAdicionarAlunoComponent;
