import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
let CrudArrayComponent = class CrudArrayComponent extends Vue {
    constructor() {
        super(...arguments);
        this.editedIndex = null;
        this.editedItem = {};
        this.editDialog = false;
        this.deleteDialog = false;
        this.items = [];
    }
    get headers() {
        return this.propHeaders;
    }
    async onArrayUpdate(val) {
        this.items = val;
    }
    get fields() {
        return this.propHeaders.filter((value) => {
            return !value.hide_field;
        });
    }
    async cancelEditItem() {
        this.editedIndex = null;
        this.$nextTick(() => {
            this.editedItem = {};
        });
    }
    async saveItem() {
        if (this.editedIndex !== null) {
            // @ts-ignore
            this.$set(this.items, this.editedIndex, this.editedItem);
        }
        else {
            // @ts-ignore
            this.items.push(this.editedItem);
        }
        this.editedIndex = null;
        this.$nextTick(() => {
            this.editedItem = {};
        });
        this.$emit('input', [...this.items]);
    }
    async editItem(item) {
        // @ts-ignore
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
    }
    async deleteItem(item) {
        // @ts-ignore
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.deleteDialog = true;
    }
    deleteItemConfirm() {
        // @ts-ignore
        this.items.splice(this.editedIndex, 1);
        this.closeDelete();
    }
    async close() {
        // @ts-ignore
        this.dialog = false;
        this.$nextTick(() => {
            // @ts-ignore
            this.editedItem = Object.assign({}, this.defaultItem);
            // @ts-ignore
            this.editedIndex = -1;
        });
    }
    async closeDelete() {
        this.deleteDialog = false;
        this.editedIndex = null;
        this.$nextTick(() => {
            this.editedItem = {};
        });
    }
    async save() {
        // @ts-ignore
        if (this.editedIndex > -1) {
            // @ts-ignore
            Object.assign(this.items[this.editedIndex], this.editedItem);
        }
        else {
            // @ts-ignore
            this.items.push(this.editedItem);
        }
        await this.close();
    }
    async mounted() {
        this.items = [...this.value];
    }
};
__decorate([
    Prop(Array)
], CrudArrayComponent.prototype, "propHeaders", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], CrudArrayComponent.prototype, "value", void 0);
__decorate([
    Prop(String)
], CrudArrayComponent.prototype, "propTitle", void 0);
__decorate([
    Watch('value')
], CrudArrayComponent.prototype, "onArrayUpdate", null);
CrudArrayComponent = __decorate([
    Component
], CrudArrayComponent);
export default CrudArrayComponent;
