var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[(!_vm.propTurmaAlunoId)?_c('span',[_vm._v(" Colocar aluno em turma ")]):_c('span',[_vm._v(" Transferir aluno de turma ")])])]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.propLivro)+" "),_c('br'),_vm._v("Modalidade no contrato "),_c('b',[_vm._v(_vm._s(_vm.cursoModalidadeOptions()[_vm.propModalidadeId - 1].nome))])]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"card-avatar, article"}}):_c('v-data-table',{attrs:{"fixed-header":"","height":"70vh","no-data-text":"Nenhuma turma foi encontrada com os critérios necessários...","headers":_vm.headers,"items":_vm.turmaItemsFiltered,"footer-props":{
        itemsPerPageOptions: [-1]
      },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.modalidade_id",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.cursoModalidadeOptions()[item.modalidade_id - 1].nome)+" ")]}},{key:"item.livro",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.livro)+" "+_vm._s(item.livro_edicao)+" ")]}},{key:"item.cronograma_horario",fn:function(ref){
      var item = ref.item;
return [_c('v-row',{attrs:{"set":_vm.groupedObj = _vm._.groupBy(item.cronograma_horario, 'dia_semana_index')}},[_c('v-col',{attrs:{"set":_vm.objKeys = Object.keys(_vm.groupedObj)}},[(_vm.objKeys.length > 1)?[_c('v-menu',{attrs:{"open-on-hover":"","right":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-badge',{attrs:{"content":"+","value":"+","color":"blue"}},[_c('span',[_vm._v(_vm._s(_vm.groupedObj[_vm.objKeys[0]][0].dia_semana))])])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.groupedObj),function(dia){return _c('v-list-item',{key:dia.dia_semana_index},[_c('v-list-item-title',[_vm._v(_vm._s(dia[0].dia_semana)+" "),_c('v-chip-group',{attrs:{"column":""}},_vm._l((dia),function(aula,idx){return _c('v-chip',{key:idx,attrs:{"x-small":""}},[_vm._v(_vm._s(aula.horario))])}),1)],1)],1)}),1)],1)]:(_vm.objKeys.length > 0)?[_vm._v(" "+_vm._s(_vm.groupedObj[_vm.objKeys[0]][0].dia_semana)+" "),_c('v-chip-group',{attrs:{"row":""}},_vm._l((_vm.groupedObj[_vm.objKeys[0]]),function(aula,idx){return _c('v-chip',{key:idx,attrs:{"x-small":""}},[_vm._v(_vm._s(aula.horario))])}),1)]:_vm._e()],2)],1)]}},{key:"item.local_aula_id",fn:function(ref){
      var item = ref.item;
return [_c('AulaLocalShowButton',{attrs:{"propEscolaId":_vm.userProfile.company.escola_id,"propAulaLocal":item.local_aula_id}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":item.vagas_disponiveis <= 0,"x-small":"","color":"primary"},on:{"click":function($event){return _vm.escolherTurmaAluno(item)}}},[(item.vagas_disponiveis <= 0)?_c('span',[_vm._v(" Vagas esgotadas ")]):_c('span',[_vm._v(" Escolher essa turma ")])])]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_vm._v("voltar")]),_c('v-btn',{attrs:{"disabled":_vm.turmaId == null || _vm.loading,"text":"","color":"red"},on:{"click":function($event){return _vm.confirmar()}}},[_vm._v("Sim, eu confirmo a "),(!_vm.propTurmaAlunoId)?_c('span',[_vm._v("adição")]):_c('span',[_vm._v(" transferência ")]),_vm._v(" do aluno "),(!_vm.propTurmaAlunoId)?_c('span',[_vm._v("na")]):_c('span',[_vm._v(" para ")]),_vm._v(" turma "),_c('b',[_vm._v(" "+_vm._s(_vm.turmaCodigoReferencia))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }