import { __decorate } from "tslib";
import { companyConfiguracaoMaterialParcelamentoStore, contratoStore, } from '@/store';
import { Vue, Component } from 'vue-property-decorator';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
let GerarFinanceiroMaterialDidaticoDialog = class GerarFinanceiroMaterialDidaticoDialog extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
        this.loading = false;
        this.saving = false;
        this.contratoId = null;
        this.contratoLivroId = null;
        this.materialDidaticoQuantidadeParcelasSelected = [];
        this.diaVencimentoParcelaLivroSelected = null;
    }
    get disableConfirm() {
        return (this.loading ||
            this.materialDidaticoQuantidadeParcelasSelected?.length == 0 ||
            !this.diaVencimentoParcelaLivroSelected ||
            this.saving);
    }
    get companyConfiguracaoMaterialParcelamentos() {
        return companyConfiguracaoMaterialParcelamentoStore.companyConfiguracaoMaterialParcelamentos;
    }
    async clear() {
        this.contratoId = null;
        this.contratoLivroId = null;
        this.materialDidaticoQuantidadeParcelasSelected = null;
        this.diaVencimentoParcelaLivroSelected = null;
        this.saving = false;
    }
    async handleConfirmar() {
        this.saving = true;
        // @ts-ignore
        const quantidade_parcelas = parseInt(this.materialDidaticoQuantidadeParcelasSelected[0].replace('x', ''), 10);
        await contratoStore.gerarFinanceiroLivro({
            contrato_id: this.contratoId,
            contrato_livro_id: this.contratoLivroId,
            data_vencimento: this.diaVencimentoParcelaLivroSelected,
            quantidade_parcelas,
        });
        this.dialog = false;
        this.saving = false;
        await this.clear();
        this.$emit('on-confirm');
    }
    async handleCancel() {
        this.dialog = false;
        await this.clear();
        this.$emit('on-cancel');
    }
    async show(contratoLivroId, contratoId) {
        this.loading = true;
        this.dialog = true;
        this.contratoId = contratoId;
        this.contratoLivroId = contratoLivroId;
        await companyConfiguracaoMaterialParcelamentoStore.getCompanyConfiguracaoMaterialParcelamentos();
        this.loading = false;
    }
};
GerarFinanceiroMaterialDidaticoDialog = __decorate([
    Component({
        components: {
            DateFieldComponent,
            ClickableCard,
        },
    })
], GerarFinanceiroMaterialDidaticoDialog);
export default GerarFinanceiroMaterialDidaticoDialog;
