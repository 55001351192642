import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { contratoStore, userActionStore } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
import RequestPermissionComponent from '@/components/RequestPermissionComponent.vue';
let ContratoLivroExcluirComponent = class ContratoLivroExcluirComponent extends Vue {
    constructor() {
        super(...arguments);
        this.actionId = null;
        this.notificacaoSelected = [];
        this.resultado = null;
        this.requestPermissionDialog = false;
        this.movimentacoes = [];
    }
    get excluirEstagioAllow() {
        return userActionStore.excluirEstagioAllow;
    }
    get excluirContratoComMovimentacaoObject() {
        return userActionStore.excluirContratoComMovimentacaoObject;
    }
    get disabledSubmit() {
        let retorno = true;
        // tem movimentacao?
        if (this.movimentacoes.length > 0) {
            if (this.excluirContratoComMovimentacaoObject?.allow ||
                this.excluirContratoComMovimentacaoObject?.request_permission) {
                retorno = false;
            }
        }
        else {
            if (this.excluirContratoComMovimentacaoObject?.allow ||
                this.excluirContratoComMovimentacaoObject?.request_permission ||
                this.excluirEstagioAllow) {
                retorno = false;
            }
        }
        return retorno;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async excluir(allowed = true) {
        if (this.excluirContratoComMovimentacaoObject?.request_permission &&
            !this.requestPermissionDialog) {
            this.actionId = this.excluirContratoComMovimentacaoObject.action_id;
            this.requestPermissionDialog = true;
            return;
        }
        if (allowed) {
            this.requestPermissionDialog = false;
            this.resultado = await contratoStore.deleteContratoLivro(this.propContratoLivro.id);
            if (this.resultado) {
                this.onCancel();
            }
        }
    }
    async mounted() {
        this.movimentacoes = await contratoStore.getContratoLivroMovimentacao(this.propContratoLivro.id);
    }
};
__decorate([
    Prop({ type: Object })
], ContratoLivroExcluirComponent.prototype, "propContratoLivro", void 0);
ContratoLivroExcluirComponent = __decorate([
    Component({
        components: {
            ClickableCard,
            RequestPermissionComponent,
        },
    })
], ContratoLivroExcluirComponent);
export default ContratoLivroExcluirComponent;
