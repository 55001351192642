import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { companyConfiguracaoNegociacaoMultaRescisoriaContratoStore, contratoStore, financeiroParcelaStore, livroStore, objecaoStore, } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
import DefinirDescontoComponent from '@/components/DefinirDescontoComponent.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
let ContratoCancelarComponent = class ContratoCancelarComponent extends Vue {
    constructor() {
        super(...arguments);
        this.financeiroParcelasItems = [];
        this.dadosCancelamento = {};
        this.negociacaoNumeroParcelas = [];
        this.negativarSelected = ['Não'];
        this.notificacaoDesistenciaAssinada = [];
        this.percentualDesconto = 0;
        this.valorDesconto = 0;
        this.objecaoSelected = null;
        this.negociacaoSelected = {
            valorDesconto: 0,
            percentualDesconto: 0,
        };
        this.servicosPrestadosHeaders = [
            {
                text: 'Livro',
                value: 'livro_id',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Turma',
                value: 'turma',
                align: 'left',
                filterable: true,
            },
            {
                text: '',
                value: 'print_btn',
                align: 'right',
            },
        ];
        this.financeiroMaterialDidaticoHeaders = [
            {
                text: 'Livro',
                value: 'livro_id',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Livro entregue',
                value: 'turma',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Financeiro',
                value: 'financeiro',
                align: 'right',
            },
        ];
        this.financeiroCursoHeaders = [
            {
                text: 'Livro',
                value: 'livro_id',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Turma',
                value: 'turma',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Financeiro',
                value: 'financeiro',
                align: 'right',
            },
        ];
        this.headers = [
            { text: 'Tipo', value: 'financeiro_categoria_nome' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor integral', value: 'valor_integral' },
            { text: 'Valor original', value: 'valor_original' },
            { text: 'Valor atualizado', value: 'valor_atualizado' },
            { text: 'Valor pago', value: 'valor_pago' },
            {
                text: 'Ultima presença',
                value: 'turma_aluno_atividade_data_ultima_presenca',
            },
            {
                text: 'livro_data_entrega',
                value: 'livro_data_entrega',
            },
        ];
        this.observacao = '';
        this.data_cancelamento = '';
    }
    get financeiroParcelas() {
        return financeiroParcelaStore.financeiroParcelas;
    }
    get livros() {
        return livroStore.livros;
    }
    get disabledSubmit() {
        return (this.notificacaoDesistenciaAssinada.length === 0 ||
            this.negativarSelected.length === 0 ||
            // @ts-ignore
            (this.dadosCancelamento.multa_valor_a_pagar +
                // @ts-ignore
                this.dadosCancelamento.total_pagar_referente_estudado +
                this.financeiroParcelaMaterialDidaticoValor >
                0 &&
                this.negociacaoNumeroParcelas.length === 0));
    }
    get financeiroParcelaMaterialDidatico() {
        // @ts-ignore
        const financeiroCategoriaMaterialDidaticoId = this.getFinanceiroCategoriaMaterialDidatico104();
        const financeiroParcelaMaterialDidaticos = _.filter(this.financeiroParcelas, (item) => 
        // @ts-ignore
        item.financeiro_categoria_id === financeiroCategoriaMaterialDidaticoId && item.livro_data_entrega);
        return financeiroParcelaMaterialDidaticos;
    }
    get objecaoOptions() {
        return _.filter(objecaoStore.objecoes, (item) => item.assunto == 'Cancelamento de contrato/Evasão');
    }
    get financeiroParcelaMaterialDidaticoValor() {
        const a = _(this.financeiroParcelaMaterialDidatico).value();
        return _.sumBy(a, 'valor_atualizado');
    }
    get financeiroParcelaCurso() {
        return _.filter(this.financeiroParcelas, (item) => 
        // @ts-ignore
        item.financeiro_categoria_id ===
            // @ts-ignore
            this.getFinanceiroCategoriaCurso105() && item.status_id === this.getFinanceiroParcelaStatusIdEmAberto());
    }
    get financeiroParcelaOutrosValor() {
        return 0;
    }
    get financeiroParcelaCursoValor() {
        const a = _(this.financeiroParcelaCurso).value();
        return _.sumBy(a, 'valor_atualizado');
    }
    getTurmaByServicoPrestado(contrato, livro_id) {
        return _.filter(contrato, (i) => {
            return i.turma.livro_id === livro_id;
        });
    }
    get companyConfiguracaoNegociacoesMultaRescisoria() {
        return companyConfiguracaoNegociacaoMultaRescisoriaContratoStore.companyConfiguracaoNegociacoesMultaRescisoriaContrato;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async rescindir() {
        // @ts-ignore
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        this.$swal({
            title: 'Confirma a rescisão do contrato do aluno?',
            html: `
      <div style="text-align: justify;"><span style="justify-content: left">Antes de prosseguir com a rescisão é importante garantir que <b style="color: red">todas a notas, presenças, manejo de contrato e financeiro estão condizentes com a realidade</b>,
      pois o sistema considerará porcentagem de curso estudados, quantidade de parcelas pagas e entregas de livro para o cálculo da rescisão.
      <b style="color: red">Após a confirmação da rescisão as informações financeiras, pedagógicos e de retenção serão definitivas</b> na conclusão do contrato por rescisão. </span></div>
      `,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let numeroParcelas = 0;
                if (this.negociacaoNumeroParcelas.length > 0) {
                    numeroParcelas = this.negociacaoNumeroParcelas[1].numero_vezes;
                }
                await contratoStore.rescindirContrato({
                    id: this.propContrato.id,
                    observacao: this.observacao,
                    // @ts-ignore
                    cancelado_objecao_id: this.objecaoSelected?.id,
                    negociacao_numero_parcelas: numeroParcelas,
                    valor_negociado: 
                    // @ts-ignore
                    this.dadosCancelamento.multa_valor_a_pagar +
                        // @ts-ignore
                        this.dadosCancelamento.total_pagar_referente_estudado +
                        this.financeiroParcelaMaterialDidaticoValor -
                        this.negociacaoSelected.valorDesconto,
                    notificacao_desistencia_assinada: this.notificacaoDesistenciaAssinada[0] === 'Sim',
                    protestar_aluno: this.negativarSelected[0] === 'Sim',
                    data_cancelamento: this.data_cancelamento
                });
                this.onCancel();
            }
        });
    }
    async created() {
        await companyConfiguracaoNegociacaoMultaRescisoriaContratoStore.getCompanyConfiguracaoNegociacoesMultaRescisoriaContrato();
        this.dadosCancelamento = await contratoStore.getDadosCancelamento(this.propContrato.id);
        this.financeiroParcelasItems =
            await financeiroParcelaStore.getFinanceiroParcelas([
                `financeiro.contrato_id = ${this.propContrato.id}`,
            ]);
    }
};
__decorate([
    Prop({ type: Object })
], ContratoCancelarComponent.prototype, "propContrato", void 0);
ContratoCancelarComponent = __decorate([
    Component({
        components: {
            ClickableCard,
            DefinirDescontoComponent,
            DateFieldComponent,
            ValidationObserver,
            ValidationProvider,
        },
    })
], ContratoCancelarComponent);
export default ContratoCancelarComponent;
