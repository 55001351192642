import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { livroStore, contratoStore } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
let ContratoAdicionarEstagioComponent = class ContratoAdicionarEstagioComponent extends Vue {
    constructor() {
        super(...arguments);
        this.livroSelected = [];
        this.exibeTodosEstagios = false;
        this.loading = false;
    }
    onCancel() {
        this.livroSelected = [];
        this.$emit('on-cancel');
    }
    get livroByCursoIdioma() {
        if (this.exibeTodosEstagios) {
            return livroStore.livros;
        }
        else {
            return _.filter(livroStore.livros, (item) => item.curso.id === this.propContratoItem.curso_id);
        }
    }
    async exibirTodosEstagios() {
        this.exibeTodosEstagios = !this.exibeTodosEstagios;
    }
    async submit() {
        this.loading = true;
        const estagio = {
            contrato_id: this.propContratoItem.id,
            // @ts-ignore
            novo_livro_id: this.livroSelected[1].id,
            // @ts-ignore
            financeiro_observacao: this.livroSelected[1].nome,
        };
        await contratoStore.adicionarEstagio(estagio);
        this.onCancel();
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Object })
], ContratoAdicionarEstagioComponent.prototype, "propContratoItem", void 0);
ContratoAdicionarEstagioComponent = __decorate([
    Component({
        components: {
            ClickableCard,
        },
    })
], ContratoAdicionarEstagioComponent);
export default ContratoAdicionarEstagioComponent;
