import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { cityStore, contratoStore, userActionStore, } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
import RequestPermissionComponent from '@/components/RequestPermissionComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import SearchCityComponent from '@/components/SearchCityComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let SolicitarLivroParaFranchiseComponent = class SolicitarLivroParaFranchiseComponent extends Vue {
    constructor() {
        super(...arguments);
        this.actionId = null;
        this.expectedStartDate = null;
        this.isPossivelSolicitarLivro = {
            permite: false,
            parcelas: [],
        };
        this.isLoadingEnviandoPedidoMaster = false;
        this.confirmaCancelamentoParcelas = false;
        this.loading = true;
        this.loadingCarregarPreInvoiceId = false;
        this.preInvoiceData = null;
        this.preInvoiceId = null;
        this.preInvoiceHeaders = [
            { text: 'Livro', value: 'item_name' },
            { text: 'Quantidade', value: 'quantity' },
            { text: 'Aluno', value: 'nome_aluno' },
        ];
        this.headerParcelas = [
            { text: 'Parcela', value: 'parcela_numero' },
            { text: 'Valor R$', value: 'valor_integral' },
            { text: 'Vencimento', value: 'vencimento' },
        ];
        this.requestPermissionDialog = false;
        this.telefoneSelected = null;
        this.tipoSolicitacaoSelected = null;
    }
    get solicitarLivroFranchisingObject() {
        return userActionStore.solicitarLivroFranchisingObject;
    }
    get entidadeResponsavelPeloPedido() {
        return this.propEntityObject.aluno_responsavel_financeiro
            ? 'ALUNO'
            : 'REPONSÁVEL FINANCEIRO';
    }
    get entityObject() {
        return this.propEntityObject.aluno_responsavel_financeiro
            ? 'aluno'
            : 'responsavel';
    }
    get entityCidadeObject() {
        return this.propEntityObject.aluno_responsavel_financeiro
            ? this.propEntityAlunoCidadeObject
            : this.propEntityResponsavelCidadeObject;
    }
    get disabledSubmit() {
        if (!this.isPossivelSolicitarLivro.permite) {
            return true;
        }
        if (this.loading) {
            return true;
        }
        if (this.tipoSolicitacaoSelected) {
            if (this.tipoSolicitacaoSelected[0] ==
                'Pedido já existente na plataforma Master') {
                return this.preInvoiceData == null;
            }
            if (this.tipoSolicitacaoSelected[0] ==
                'Pedido não existente na plataforma Master') {
                return false;
            }
        }
        return true;
    }
    onCancel() {
        this.tipoSolicitacaoSelected = null;
        this.$emit('on-cancel');
    }
    onConfirm() {
        this.$emit('on-confirm');
    }
    async carregarPreInvoiceId() {
        this.loadingCarregarPreInvoiceId = true;
        this.preInvoiceData = await contratoStore.getMasterPreInvoice({
            preInvoiceId: this.preInvoiceId,
            contratoLivroId: this.propContratoLivroObject.id,
        });
        this.loadingCarregarPreInvoiceId = false;
    }
    async submit(token = null) {
        if (this.solicitarLivroFranchisingObject?.request_permission &&
            !this.requestPermissionDialog) {
            this.actionId = this.solicitarLivroFranchisingObject.action_id;
            this.requestPermissionDialog = true;
            return;
        }
        // @ts-ignore
        if (this.tipoSolicitacaoSelected[0] ==
            'Pedido não existente na plataforma Master') {
            // @ts-ignore
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                return;
            }
        }
        this.isLoadingEnviandoPedidoMaster = true;
        this.requestPermissionDialog = false;
        const entity = {
            nome: this.propEntityObject[`${this.entityObject}_nome`],
            cpf: this.propEntityObject[`${this.entityObject}_cpf`],
            email: this.propEntityObject[`${this.entityObject}_email`],
            cep: this.propEntityObject[`${this.entityObject}_cep`],
            bairro: this.propEntityObject[`${this.entityObject}_bairro`],
            rua: this.propEntityObject[`${this.entityObject}_rua`],
            numero: this.propEntityObject[`${this.entityObject}_numero`],
            celular: this.propEntityObject[`${this.entityObject}_celular`],
            previsao_inicio: this.expectedStartDate,
        };
        const payload = {
            contrato_livro_id: this.propContratoLivroObject.id,
            pre_invoice_id: this.preInvoiceId,
            // @ts-ignore
            pre_invoice_status_id: 1,
            otp_token: token,
            entity,
        };
        const result = await contratoStore.solicitarLivroParaFranchiseEstagioLivro(payload);
        if (result) {
            this.onConfirm();
        }
        this.isLoadingEnviandoPedidoMaster = false;
    }
    async onTipoSolicitacaoSelected() {
        this.preInvoiceId = null;
        this.preInvoiceData = null;
    }
    async mounted() {
        this.loading = true;
        this.isPossivelSolicitarLivro =
            await contratoStore.solicitarLivroParaFranchiseVerificaPossibilidade(this.propContratoLivroObject.id);
        if (this.entityCidadeObject &&
            this.entityCidadeObject.codigo_municipio_completo) {
            this.cidadeAluno = await cityStore.getCityByCodigoMunicipio(this.entityCidadeObject.codigo_municipio_completo);
        }
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Object })
], SolicitarLivroParaFranchiseComponent.prototype, "propContratoLivroObject", void 0);
__decorate([
    Prop({ type: Object })
], SolicitarLivroParaFranchiseComponent.prototype, "propEntityObject", void 0);
__decorate([
    Prop({ type: Object })
], SolicitarLivroParaFranchiseComponent.prototype, "propEntityAlunoCidadeObject", void 0);
__decorate([
    Prop({ type: Object })
], SolicitarLivroParaFranchiseComponent.prototype, "propEntityResponsavelCidadeObject", void 0);
__decorate([
    Watch('tipoSolicitacaoSelected')
], SolicitarLivroParaFranchiseComponent.prototype, "onTipoSolicitacaoSelected", null);
SolicitarLivroParaFranchiseComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            ClickableCard,
            RequestPermissionComponent,
            DateFieldComponent,
            SearchCityComponent,
        },
    })
], SolicitarLivroParaFranchiseComponent);
export default SolicitarLivroParaFranchiseComponent;
