export function newContrato() {
    return {
        curso_id: null,
        preferencia_dia_semana: null,
        preferencia_periodo: null,
        taxa_matricula_valor: null,
        material_didatico_valor_integral: null,
        material_didatico_desconto_percentual: null,
        curso_valor_integral: null,
        curso_desconto_percentual: null,
        servicos_prestados: [],
        material_didatico_dia_vencimento: null,
        material_quantidade_parcelas: null,
        curso_dia_vencimento: null,
        curso_quantidade_parcelas: null,
        modalidade_id: null,
        aluno_id: null,
        turma_id: null,
        company_id: null,
        matricula_tipo_id: null,
        indicou_aluno_id: null,
        local_aula_id: 1,
    };
}
