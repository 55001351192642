import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { contratoStore } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
let ContratoTrancarComponent = class ContratoTrancarComponent extends Vue {
    constructor() {
        super(...arguments);
        this.notificacaoSelected = [];
        this.observacao = '';
        this.objecaoSelected = [];
        this.trancado_previsao_retorno = null;
        this.objecaoByAssuntoOptions = [
            {
                assunto: 'Trancamento',
                departamento_id: 2,
                id: 47,
                nome: 'Problemas de saúde',
            },
            {
                assunto: 'Trancamento',
                departamento_id: 2,
                id: 140,
                nome: 'Motivos financeiros',
            },
            {
                assunto: 'Trancamento',
                departamento_id: 2,
                id: 141,
                nome: 'Falta de tempo',
            },
            {
                assunto: 'Trancamento',
                departamento_id: 2,
                id: 48,
                nome: 'Outras prioridades',
            },
        ];
    }
    get operacaoLabel() {
        if (this.propOperacao === 1) {
            return 'Trancar';
        }
        else {
            return 'Destrancar';
        }
    }
    onCancel() {
        this.notificacaoSelected = [];
        this.observacao = '';
        this.objecaoSelected = [];
        this.$emit('on-cancel');
    }
    async submit() {
        let objecao_id = 145; // destrancar
        if (this.propOperacao === 1) {
            // @ts-ignore
            objecao_id = this.objecaoSelected[1].id;
        }
        if (this.trancado_previsao_retorno == '') {
            this.trancado_previsao_retorno = null;
        }
        await contratoStore.trancarContrato({
            id: this.propContrato.id,
            operacao: this.propOperacao,
            trancado_observacao: this.observacao,
            trancado_previsao_retorno: this.trancado_previsao_retorno,
            objecao_id,
        });
        this.onCancel();
    }
};
__decorate([
    Prop({ type: Object })
], ContratoTrancarComponent.prototype, "propContrato", void 0);
__decorate([
    Prop({ type: Number })
], ContratoTrancarComponent.prototype, "propOperacao", void 0);
ContratoTrancarComponent = __decorate([
    Component({
        components: {
            ClickableCard,
            DateFieldComponent,
        },
    })
], ContratoTrancarComponent);
export default ContratoTrancarComponent;
